<template>
    <Modal
        v-model="isShowGenerateModal"
        :title="title"
        :isFloat="false"
        :width="modalSize"
    >
        <template #modal-content class="modal-content">
            <div class="state">
                <div v-if="generateState === 'success'" class="state-success">
                    <div class="title distance">報表已開始生產囉</div>
                    <div>
                        請稍後點選「更新報表狀態按鈕」以確認「報表狀態」。
                    </div>
                </div>
                <div
                    v-if="generateState === 'generate-failure'"
                    class="state-failure"
                >
                    <div class="title distance">生產報表失敗</div>
                    <div>請重新整理頁面或聯繫資訊部同仁。</div>
                </div>
                <div
                    v-if="generateState === 'updated-failure'"
                    class="state-failure"
                >
                    <div class="title distance">更新報表失敗</div>
                    <div>請重新整理頁面或聯繫資訊部同仁。</div>
                </div>
                <div
                    v-if="generateState === 'download-failure'"
                    class="state-failure"
                >
                    <div class="title distance">下載報表失敗</div>
                    <div>請重新整理頁面或聯繫資訊部同仁。</div>
                </div>
            </div>
        </template>
        <template #modal-bottom>
            <div class="modal-bottom">
                <div class="finished">
                    <Button
                        buttonWord="關閉"
                        buttonStyle="grey"
                        @click="closeModal"
                    />
                </div>
            </div>
        </template>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
export default {
    name: 'DownloadModal',
    components: { Modal, Button },
    emits: ['update:action'],
    props: {
        title: {
            type: String
        },
        modalSize: {
            type: String
        },
        action: {
            type: String
        }
    },
    methods: {
        closeModal: function () {
            this.isShowGenerateModal = false
        }
    },
    watch: {
        action(change) {
            if (change !== null) {
                this.isShowGenerateModal = true
                this.generateState = change
            }
        },
        isShowGenerateModal(change) {
            if (!change) {
                this.$emit('update:action', null)
            }
        }
    },
    data() {
        return {
            generateState: this.action, //downloading
            isShowGenerateModal: false
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.modal) {
    &-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .icon-size {
            width: 50px;
            height: 50px;
        }
    }
    &-bottom {
        .loading {
            align-items: center;
            text-align: center;
            font-size: 15px;
            .normal-text {
                color: $secondary-grey;
            }
            .loading-text {
                color: $fourth-red;
                font-weight: 500;
            }
        }
        .finished {
            align-items: flex-end;
        }
    }
}
.state {
    &-success {
        .title {
            color: $primary-green;
            font-size: 32px;
            font-weight: 700;
        }
        .distance {
            margin-bottom: 10px;
        }
    }
    &-failure {
        .title {
            color: $fourth-red;
            font-size: 32px;
            font-weight: 700;
        }
        .distance {
            margin-bottom: 10px;
        }
    }
    &-downloading {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}
</style>
